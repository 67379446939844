import React from "react"
import PropTypes from "prop-types"

// Utilities
import kebabCase from "lodash/kebabCase"

// Components
import { Helmet } from "react-helmet"
import { Link, graphql } from "gatsby"

import Layout from '../components/Layout'
import SEO from "../components/seo"
import { CardContent, Chip, Divider, List, ListItem, ListItemText, Paper } from "@material-ui/core"
import CodeIcon from "@material-ui/icons/Code"

const pageTitle = "Technologies you can use"

const TagsPage = ({
  data: {
    allJobsJson: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => (
<Layout>
  <SEO title={pageTitle} description="A list of technologies that you can use at companies who provides Visa sponsorship."/>
  <div>
    <Helmet title={pageTitle} />
    <Paper>
      <CardContent>
      <Chip
            icon={<CodeIcon />}
            label="Technologies you can work with"
            color="primary"
            variant="outlined"
      />
      <List>
        {group.map(tag => (
          <ListItem key={tag.fieldValue} dense divider>
            <ListItemText>
              <Link key={tag.fieldValue} to={`/tags/${kebabCase(tag.fieldValue)}`}>
                <Chip key={`chip-${tag.fieldValue}`} label={`#${tag.fieldValue} (${tag.totalCount})`} size='small' clickable={true} />
              </Link>
            </ListItemText>
          </ListItem>
        ))}
      </List>
      </CardContent>
    </Paper>
  </div>
</Layout>
)

TagsPage.propTypes = {
  data: PropTypes.shape({
    allJobsJson: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default TagsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allJobsJson(limit: 2000) {
      group(field: categories) {
        fieldValue
        totalCount
      }
    }
  }
`